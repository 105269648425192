import React from "react"
import { Layout } from "@components/layout"
import { wrapper } from "./styles/rodo.module.scss"
import { AnimatedButton } from "@components/shared"
import { regulations__btn } from "./styles/rules.module.scss"
const Rules = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout {...page}>
      <div className={wrapper}>
        <h1>Regulaminy</h1>
        <div className="row">
          <AnimatedButton className="regulations__btn">
            <a
              className={regulations__btn}
              target="_blank"
              rel="noreferrer"
              href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Regulamin-sklepu-internetowego.pdf"
              download
            >
              Pobierz
            </a>
          </AnimatedButton>{" "}
          <h5>Regulamin sklepu</h5>
        </div>

        <div className="row">
          <AnimatedButton className="regulations__btn">
            <a
              className={regulations__btn}
              target="_blank"
              rel="noreferrer"
              href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Zgody-na-przetwarzanie-danych-do-regulaminu-checkboxy-Dla-Beneficjentów.pdf"
              download
            >
              Pobierz
            </a>
          </AnimatedButton>{" "}
          <h5>Zgoda na przetwarzanie danych osobowych</h5>
        </div>

        <div className="row">
          <AnimatedButton className="regulations__btn">
            <a
              className={regulations__btn}
              target="_blank"
              rel="noreferrer"
              href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/Obowiązek-informacyjny-RODO-Fundacja-Beneficjenci.pdf"
              download
            >
              Pobierz
            </a>
          </AnimatedButton>{" "}
          <h5>Obowiązek informacyjny RODO</h5>
        </div>

        <div className="row">
          <AnimatedButton className="regulations__btn">
            <a
              className={regulations__btn}
              target="_blank"
              rel="noreferrer"
              href="http://gladiatorzy.api.localhost-group.com/wp-content/uploads/2020/07/polityka-cookies.pdf"
              download
            >
              Pobierz
            </a>
          </AnimatedButton>{" "}
          <h5>Polityka plików cookies</h5>
        </div>
      </div>
    </Layout>
  )
}

export default Rules
